// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-genre-js": () => import("./../../../src/pages/genre.js" /* webpackChunkName: "component---src-pages-genre-js" */),
  "component---src-pages-helvetia-2050-js": () => import("./../../../src/pages/helvetia2050.js" /* webpackChunkName: "component---src-pages-helvetia-2050-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lygia-aurelia-js": () => import("./../../../src/pages/lygiaAurelia.js" /* webpackChunkName: "component---src-pages-lygia-aurelia-js" */),
  "component---src-pages-science-js": () => import("./../../../src/pages/science.js" /* webpackChunkName: "component---src-pages-science-js" */),
  "component---src-pages-trashquiz-js": () => import("./../../../src/pages/trashquiz.js" /* webpackChunkName: "component---src-pages-trashquiz-js" */)
}

